.blocks_header {
    margin: 40px auto 3rem;
    padding: 0 20px;
    max-width: 1100px;
}
.MuiCard-root {
    overflow: hidden;
}
.MuiPaper-elevation1 {
    box-shadow: none;
}


@media only screen and (max-width: 900px) {
 .dynamic_ {
    margin: 0 0 10px 0 !important;
    padding: 0 10px;
}
}

.dynamic_ {
    border: none;
    /* margin: 0 0 20px 0 !important; */
    margin: 0 0 30px 0 !important;
    padding: 0 10px;
    flex-basis: 32.3333%;
    text-align: center;
}
.cards_route{
  height: 100%;
    padding: 20px 0;
    /* border-radius: 6px; */
}
.card_text{
  margin-bottom: 0;
}

.content{
  font-size: 20px;
    margin-top: 5px;
}
.content b{
      padding-right: 5px;
}
.bold_content{
  font-size: 1.75rem;
}
